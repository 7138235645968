<template>
    <button :class="['btn btn-sm text-white', button_variant[status] || 'btn-secondary']">
        {{ $t('crud.orders.statuses.' + status) }}
    </button>
</template>


<script>
    export default {
        props: ['props'],
        mounted() {
            this.order_id = this.props.rowData.id;
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        data() {
            return {
                loaded: false,
                loading: false,
                order_id: undefined,
                button_variant: {
                    1: 'btn-secondary',
                    2: 'btn-warning',
                    3: 'btn-success',
                    4: 'btn-info',
                    5: 'btn-danger',
                    6: 'btn-purple',
                    7: 'btn-orange'
                }
            }
        },
        computed: {
            status() {
                return this.props.rowData.status;
            }
        },
        methods: {
            update(status) {
                this.loading = true;
                this.$http.put(`orders/${this.order_id}`, {
                    status: status
                }).then(() => {
                    // Change passed props so the vuetable sees the update.
                    this.props.rowData.status = status;
                    this.status = status;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
