<template>
    <b-row class="animated fadeIn">
        <b-col cols="12">
            <div class="card" v-if="filters.length">
                <div class="card-body">
                    <slot name="pre-filters" :filters="filters"></slot>
                    <crud-filters-component v-model="filters" :filter-search="filter_search"></crud-filters-component>
                </div>
            </div>
            <slot name="pre-card"></slot>
            <div class="card">
                <div class="card-header py-1 searchable">
                    <div class="row justify-content-between">
                        <div class="col">
                            <loading v-if="loading"></loading>
                            <slot name="header"></slot>
                        </div>
                        <div class="col-2"><input class="form-control form-control-sm" v-model="filter_search"
                                                  placeholder="Zoeken..."/></div>
                        <div class="col text-right"><span v-if="pagination">{{ pagination.from }} - {{ pagination.to > pagination.total ? pagination.total : pagination.to }} of {{ pagination.total }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="my-2">
                        <slot name="pre-table">
                            <template v-if="crud.routes.create">
                                <router-link :to="{name: crud.routes.create}" class="btn btn-primary btn-sm mr-2">{{
                                    $t('crud.new') }}
                                </router-link>
                            </template>
                        </slot>
                    </div>
                    <vuetable ref="vuetable"
                              v-bind:fields="fields"
                              v-bind:apiUrl="apiUrl"
                              v-bind:css="$data._css"
                              v-bind:http-fetch="getData"
                              v-bind:query-params="queryParams"
                              v-bind:initial-page="query_from_url.page"
                              v-bind:per-page="query_from_url.per_page"
                              v-bind:sort-order="query_from_url.sort_order"
                              v-bind:transform="transform"
                              v-bind:no-data-template="noDataTemplate"
                              @vuetable:loading="onLoading"
                              @vuetable:load-success="onLoadSuccess"
                              @vuetable:load-error="onLoadError"
                              @vuetable:pagination-data="onPaginationData"
                              @vuetable:row-clicked="onRowClicked"
                    >
                        <span slot="slot-actions" slot-scope="props"
                              style="white-space: nowrap; text-align: right; display: block;"
                              :key="props.rowData.id + props.rowData.payment_status + props.rowData.status">
                            <slot name="pre-actions" :row-data="props.rowData"></slot>
                            <delete-button :props="props"
                                           @click="props.rowData.deleted_at ? undoRemove(props.rowData.id).then($event).catch($event) : remove(props.rowData.id).then($event).catch($event)"/>
                        </span>
                        <!-- Define order slots here, since there seems to be no way to pass them down from orders\Index.vue -->
                        <template slot="order-payment-status" slot-scope="props">
                            <order-payment-status :props="props"
                                                  :key="props.rowData.id + props.rowData.payment_status"/>
                        </template>
                        <template slot="order-status" slot-scope="props">
                            <order-status :props="props" :key="props.rowData.id + props.rowData.status"/>
                        </template>
                        <template slot="order-note" slot-scope="props">
                            <i v-if="props.rowData.note" class="fa fa-file-text-o" v-b-tooltip.hover
                               :title="props.rowData.note"></i>
                        </template>
                        <template slot="select-item" slot-scope="props">
                            <span @click="onSelectRow(props.rowData)" class="select-row-wrapper"
                                  :key="props.rowData.id">
                            <button :class="['btn btn-xs', props.rowData._selected ? 'btn-primary' : 'btn-outline-secondary']">
                                <i class="fa fa-check fa-fw" style="margin: 0 0.15rem"></i>
                            </button>
                            </span>
                        </template>
                    </vuetable>
                    <div class="text-center">
                        <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage">
                        </vuetable-pagination>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import VuetablePagination from '../crud/VuetablePagination';
    import {functions} from '../crud/mixin_config';
    import OrderPaymentStatus from '../orders/PaymentStatus';
    import OrderStatus from '../orders/Status';
    import DeleteButton from './DeleteButton';
    import CrudFiltersComponent from './Filters';

    export default {
        name: 'crud-index-component',
        mixins: [functions],
        components: {OrderPaymentStatus, OrderStatus, DeleteButton, CrudFiltersComponent, VuetablePagination},
        props: {
            fields: Array,
            crud: Object,
            refresh: {
                type: Number,
                default: 0
            }
        },
        beforeMount() {
            this.setParamsFromUrl();
        },
        mounted() {
            if(this.refresh) {
                this.refresh_interval = setInterval(() => {
                    if(document.hasFocus()) {
                        this.refreshTable();
                    }
                }, this.refresh);
            }
        },
        destroyed() {
            if(this.refresh_interval) {
                clearInterval(this.refresh_interval);
            }
        },
        computed: {
            apiUrl() {
                return this.$http.defaults.baseURL + '/' + this.crud.model + '/datatable';
            },
            noDataTemplate() {
                return this.loading ? 'Laden...' : this.$t('crud.no_data');
            },
            selectedIds() {
                let ids = [];
                if(this.$refs.vuetable.$data.tableData) {
                    this.$refs.vuetable.$data.tableData.forEach((item) => {
                        if(item._selected) {
                            ids.push(item.id);
                        }
                    });
                }
                return ids;
            }
        },
        data() {
            return {
                filters: [],
                data_filters: [],
                filter_search: '',
                loading: false,
                transformed: false,
                pagination: undefined,
                refresh_interval: undefined,
                query_from_url: {},
                _css: {
                    sortableIcon: 'fa fa-sort mt-1',
                    ascendingIcon: 'fa fa-sort-asc mt-1',
                    descendingIcon: 'fa fa-sort-desc mt-1',
                    tableClass: 'table table-hover'
                }
            }
        },
        watch: {
            filter_search: debounce(function() {
                // Reload table when search input changes. 'refresh' goes back to page 1
                this.$refs.vuetable.refresh();
            }, 800),
            filters: {
                handler() {
                    this.$emit('update-filters', this.filters);
                    this.refreshTable();
                },
                deep: true
            }
        },
        methods: {
            refreshTable() {
                this.$refs.vuetable.reload();
            },
            /**
             * Overwrite data retrieval function to use the correct axios instance.
             * The vuetable axios instance does not have the correct header settings etc.
             */
            getData(apiUrl, httpOptions) {
                return this.$http.get(apiUrl, httpOptions)
            },
            transform(data) {
                data.data.map((item) => {
                    item._selected = this.selectedIds.indexOf(item.id) >= 0;
                });

                this.pagination = data.links.pagination;
                if(!this.transformed) {
                    this.filters = data.filters;
                    this.transformed = true;
                }
                return data;
            },

            setParamsFromUrl() {
                this.query_from_url = {
                    page: parseInt(this.$route.query.page) || 1,
                    per_page: parseInt(this.$route.query.per_page) || 15,
                    sort_order: [{
                        sortField: this.$route.query.order,
                        field: this.$route.query.order,
                        direction: this.$route.query.sort,
                    }],
                    filters: JSON.parse(this.$route.query.filters || '{}'),
                };
                this.filter_search = this.query_from_url.filters.search || '';
            },
            onSelectRow(rowData) {
                this.$set(rowData, '_selected', !rowData._selected);
                this.$emit('update-table-data', this.$refs.vuetable.$data.tableData);
            },
            /**
             * Vuetable helper function that customizes the query params.
             */
            queryParams(sortOrder, currentPage, perPage) {
                let query = {
                    order: sortOrder.length ? sortOrder[0].sortField : undefined,
                    sort: sortOrder.length ? sortOrder[0].direction : undefined,
                    page: currentPage,
                    per_page: perPage
                };

                let filters = this.query_from_url.filters;
                this.filters.forEach(function(filter) {
                    if(!filter.value || (Array.isArray(filter.value) && !filter.value.length)) {
                        delete filters[filter.key];
                    } else {
                        filters[filter.key] = filter.value;
                    }
                });

                filters.search = this.filter_search.length ? this.filter_search : undefined;

                query.filters = JSON.stringify(filters);

                let newQuery = {...this.$route.query, ...query};

                // Replace hash in URL so it can be copied by the user. This also doesnt trigger a hash change that
                // would otherwise reload the page.
                history.replaceState(null, null, document.location.pathname + '#' + this.$route.path + '?' + $.param(newQuery));
                return newQuery;
            },
            onLoading() {
                this.loading = true;
            },
            onLoadSuccess() {
                this.$nextTick(() => {
                    // Re-emit the selected-rows property because it does not get automatically emitted.
                    this.$emit('update-table-data', this.$refs.vuetable.$data.tableData);
                    this.$emit('vuetable', this.$refs.vuetable);
                });
                this.loading = false;
            },
            onLoadError(response) {
                this.loading = false;
            },
            /**
             * When the pagination data is available, set it to pagination component.
             * @param paginationData
             */
            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData)
            },
            /**
             * On change page.
             * @param page
             */
            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },
            onRowClicked(row) {
                if(this.query_from_url && this.query_from_url.filters && this.query_from_url.filters.deleted) {
                    this.$toastr.i(this.$t('notifications.404', {object: this.$t('crud.' + this.crud.model + '._singular')}));
                    return;
                }

                if(['TD'].indexOf(row.event.target.tagName) >= 0) {
                    this.$router.push({name: this.crud.name + '.show', params: {id: row.data.id}});
                }
            }
        }
    }
</script>

<style>
    .card-header.searchable {
        line-height: 28px;
    }

    .vuetable-body td {
        cursor: pointer;
    }

    .vuetable-body .select-row-wrapper {
        padding: 1.1rem 1rem;
        margin: -0.75rem;
    }
</style>
