<template>
    <button :class="['btn btn-sm text-white', 'btn-' + button_variant[paymentStatus] || 'btn-secondary']">
        {{ this.$t('crud.orders.payment_statuses.' + paymentStatus) }}
    </button>
</template>

<script>
export default {
    props: ['props'],
    mounted() {
        this.order_id = this.props.rowData.id;
        this.$nextTick(() => {
            this.loaded = true;
        });
    },
    data() {
        return {
            loaded: false,
            loading: false,
            order_id: undefined,
            button_variant: {
                1: 'success',
                2: 'warning',
                3: 'info'
            }
        }
    },
    computed: {
        paymentStatus() {
            return this.props.rowData.payment_status;
        }
    },
    methods: {
        update(payment_status) {
            this.loading = true;
            this.$http.put(`orders/${this.order_id}`, {
                payment_status: payment_status
            }).then(() => {
                // Change passed props so the vuetable sees the update.
                this.props.rowData.payment_status = payment_status;
                this.payment_status = payment_status;
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        }
    }
}
</script>
