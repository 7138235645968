<template>
    <button class="btn btn-sm btn-outline-danger" v-b-tooltip :title="title"
            @click="clicked()">
        <loading v-if="loading"/>
        <i class="fa fa-undo fa-fw" v-else-if="props.rowData.deleted_at"></i>
        <i class="fa fa-trash-o fa-fw" v-else></i>
    </button>
</template>

<script>
    export default {
        props: ['props'],
        data() {
            return {
                loading: false,
                title: this.props.rowData.deleted_at ? this.$t('crud.undo_deletion') : this.$t('crud.delete')
            }
        },
        methods: {
            clicked() {
                this.loading = true;
                this.$emit('click', () => {
                    this.loading = false;
                });
            }
        }
    }
</script>
