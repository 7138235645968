<template>
  <div v-show="tablePagination" class="btn-group">
    <button @click="loadPage(firstPage)"
      :class="['btn', 'btn-outline-primary', isOnFirstPage ? 'disabled' : '']">
        <i class="fa fa-angle-double-left"></i>
    </button>
    <button @click="loadPage('prev')"
       :class="['btn', 'btn-outline-primary', isOnFirstPage ? 'disabled' : '']">
        <i class="fa fa-angle-left"></i>
    </button>
    <template v-if="notEnoughPages">
      <template v-for="(n, i) in totalPage">
        <button @click="loadPage(i+firstPage)" :key="i"
           :class="['btn btn-outline-primary', isCurrentPage(i+firstPage) ? 'active' : '']"
          v-html="n">
        </button>
      </template>
    </template>
    <template v-else>
      <template v-for="(n, i) in windowSize">
        <button @click="loadPage(windowStart+i+firstPage-1)" :key="i"
          :class="['btn btn-outline-primary', isCurrentPage(windowStart+i+firstPage-1) ? 'active' : '']"
          v-html="windowStart+n-1">
        </button>
      </template>
    </template>
    <button @click="loadPage('next')"
       :class="['btn', 'btn-outline-primary', isOnLastPage ? 'disabled' : '']">
      <i class="fa fa-angle-right"></i>
    </button>
    <button @click="loadPage(lastPage)"
       :class="['btn', 'btn-outline-primary', isOnLastPage ? 'disabled' : '']">
      <i class="fa fa-angle-double-right"></i>
    </button>
  </div>
</template>

<script>
import PaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin';

export default {
  mixins: [PaginationMixin],
}
</script>
<style>
  .vuetable-pagination {
    background: #f9fafb !important;
  }
</style>
