<template>
    <div>
        <div class="row filters">
            <template v-for="(filter) in filters">
                <div v-if="!filter.hidden" class="form-group col-md-3 col-lg-2">
                    <template v-if="filter.type === 'select'">
                        <b-form-select :id="'filter-' + filter.key" v-model="filter.value" size="sm">
                            <option :value="[]">[ {{ filter.title }} ]</option>
                            <option v-for="option in filter.options" :value="option.value">{{ option.text }}</option>
                        </b-form-select>
                    </template>
                    <template v-else-if="filter.type === 'datepicker'">
                      <input :id="'filter-' + filter.key" placeholder="yyyy-mm-dd" data-toggle="datetimepicker" data-target="#filter-shipping_date" class="form-control form-control-sm" type="text"
                                      :value="filter.value"/>
                    </template>
                    <template v-else>
                        <b-form-input :id="'filter-' + filter.key" type="text" size="sm"
                                      v-model="filter.value" @change="$emit('on-filter')">
                        </b-form-input>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value', 'filterSearch'],
        mounted() {
          this.filters = this.value;

            $('#filter-shipping_date').datetimepicker({
              format: 'YYYY-MM-DD',
              locale: window.locale,
              useCurrent: false,
            });

            $('#filter-shipping_date').on('change.datetimepicker', (e) => {
              this.filters.map(filter => {
                if(filter.key === 'shipping_date_from')
                  filter.value = null;

                if(filter.key === 'shipping_date_to')
                  filter.value = null;

                if(filter.key === 'shipping_date')
                  filter.value = e.target.value;
              });
            });
        },
        data() {
            return {
                filters: []
            }
        },
        computed: {
            amount_of_filters() {
                let amount = 0;
                this.filters.forEach((filter) => {
                    if(filter.value) {
                        amount += Array.isArray(filter.value) ? filter.value.length : 1;
                    }
                });
                return amount;
            }
        },
        watch: {
            filters() {
                this.$emit('input', this.filters);
            },
            value: {
                handler() {
                    this.filters = this.value;
                },
                immediate: true
            }
        },
        methods: {
            /**
             * Custom handler to select multiple options without using ctrl.
             * @param {Object} e Event
             * @param {Object} filter The filter object
             * @param {String} opt The selected option
             */
            mousedown(e, filter, opt) {
                e.preventDefault();
                let index = filter.value.indexOf(opt);
                if(index >= 0) {
                    filter.value.splice(index, 1);
                } else {
                    filter.value.push(opt);
                }
                return false;
            },
            reset() {
                this.filters.forEach((filter) => {
                    filter.value = filter.type === 'select' ? [] : null;
                });
            },
        }
    }
</script>

<style>
    .filters label {
        font-size: 0.75rem;
        margin-bottom: 0;
    }
    .filters .form-group {
        margin-bottom: 0.5rem;
    }
</style>
